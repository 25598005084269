html, body {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  width: 100vw;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.list-item {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.login-screen {
  opacity: 0;
  animation: fade-form 1.5s ease-in forwards;
}

.login-form {
  opacity: 0;
  animation: fade-form 1.5s ease-in forwards;
  animation-delay: 1.5s;
}

@keyframes fade-form {
  from {opacity: 0;}
  to {opacity: 1;}
}

.login-layover {
  width: 100px;
  height: 100px;
  animation: fade 3s ease-out forwards;
  animation-delay: 2s;
  background-color:rgba(0, 0, 0, 0);
  
}

.login-screen-hide {
  opacity: 1;
  animation: fade-out 1s ease-in forwards;
  animation-delay: 0s;
}

@keyframes fade-out {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fade {
  from {background-color:rgba(0, 0, 0, 0); }
  to {background-color:rgba(5,26,50, 0.9); }
}

.main-screen {
  opacity: 0;
  animation: fade-form 0.5s ease-in forwards;
}

.recharts-wrapper text {
  font-family: 'Source Sans Pro';
}

.recharts-legend-item-text {
  font-family: 'Source Sans Pro';
}

.recharts-default-tooltip {
  font-family: 'Source Sans Pro';
}

@keyframes loading {
  0%   { opacity:0.3; }
  50%  { opacity:1; }
  100% { opacity:0.3; }
}

.onacta-loading {
  animation: loading 2s infinite;
}